























































































import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
  toRefs
} from "@vue/composition-api"

import type { PropType } from "@vue/composition-api"

import ResizableText from "@/components/GroupTeams/Common/Games/ResizableText.vue"
import AppUserCard from "@/components/UserCard/AppUserCard.vue"
import MissionCard from "@/components/GroupTeams/Common/Games/GameCardParts/MissionCard.vue"
import BigUglyButton from "../../BuzzIn/BigUglyButton.vue"
import JeopardAiUsersGrid from "./JeopardAiUsersGrid.vue"
import JeopardAiWager from "./JeopardAiWager.vue"
import JeopardAiChallengeForm from "./JeopardAiChallengeForm.vue"
import JeopardAiTitle from "./JeopardAiTitle.vue"
import JeopardAiPrice from "./JeopardAiPrice.vue"
import JeopardAiQuestionText from "./JeopardAiQuestionText.vue"
import JeopardAiLabel from "./JeopardAiLabel.vue"
import JeopardAiTimer from "./JeopardAiTimer.vue"
import JeopardAiHint from "./JeopardAiHint.vue"

import useJeopardAiQuestionUI from "../use/useJeopardAiQuestionUI"

import { AnswersMode } from "../enums"

import type { Question, State } from "../types"
import { BUZZ_BUTTON_DELAY } from "../constants"

export default defineComponent({
  name: "JeopardAiQuestionMobile",
  components: {
    AppUserCard,
    MissionCard,
    ResizableText,
    BigUglyButton,
    JeopardAiUsersGrid,
    JeopardAiWager,
    JeopardAiPrice,
    JeopardAiLabel,
    JeopardAiHint,
    JeopardAiTimer,
    JeopardAiQuestionText,
    JeopardAiChallengeForm,
    JeopardAiTitle
  },
  props: {
    mode: {
      type: String as PropType<AnswersMode>,
      required: false,
      default: AnswersMode.NORMAL
    },
    state: {
      type: Object as PropType<State>,
      required: true
    },
    question: {
      type: Object as PropType<Question>,
      required: true
    }
  },
  setup(props) {
    const { state } = toRefs(props)
    const {
      answers,
      amount,
      min,
      challenger,
      challenge,
      finale,
      viewer,
      dailyDoubleTeam,
      count,
      self,
      hasQueue,
      hasAnswers,
      answer,
      submit,
      regenerate,
      players,
      button,
      queue,
      score,
      fail,
      complete,
      reset,
      isViewerHostLike,
      wager,
      isQuestionVisible,
      addCommasToNumber,
      hasPlayers,
      time,
      double,
      submitWagerAnswer,
      submitWagerAmount
    } = useJeopardAiQuestionUI(props)

    const intervalID = ref(null)

    onMounted(() => {
      const timestamp = state.value.timestamp

      if (timestamp != null && double.value === false) {
        const now = Date.now()
        const diff = timestamp + BUZZ_BUTTON_DELAY - now
        const n = Math.floor(diff / 1000)
        if (n > 0) {
          count.value = n
          const interval = setInterval(() => {
            if (count.value === 0) clearInterval(interval)
            count.value = count.value - 1
          }, 1000)
        }
      }

      intervalID.value = setInterval(() => {
        time.value = Date.now()
      }, 1000)
    })

    onBeforeUnmount(() => {
      clearInterval(intervalID.value)
    })

    return {
      submitWagerAnswer,
      submitWagerAmount,
      time,
      answers,
      amount,
      min,
      challenger,
      challenge,
      finale,
      viewer,
      dailyDoubleTeam,
      count,
      self,
      hasQueue,
      hasAnswers,
      answer,
      submit,
      regenerate,
      players,
      button,
      queue,
      score,
      fail,
      complete,
      reset,
      isViewerHostLike,
      wager,
      isQuestionVisible,
      addCommasToNumber,
      hasPlayers
    }
  }
})
