var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "jeopardai-question-mobile pb-2" },
    [
      _vm.challenge != null && _vm.challenger != null
        ? _c("JeopardAiChallengeForm", {
            key: _vm.challenge.id,
            attrs: {
              item: _vm.challenge,
              question: _vm.question,
              challenger: _vm.challenger,
            },
          })
        : _c(
            "MissionCard",
            { staticClass: "jeopardai-question-mobile__card" },
            [
              _c(
                "div",
                { staticClass: "jeopardai-question-mobile__title" },
                [
                  _c("JeopardAiTitle", {
                    attrs: { value: _vm.question.category },
                  }),
                ],
                1
              ),
              _vm.isQuestionVisible
                ? _c("JeopardAiQuestionText", {
                    staticClass: "mt-1",
                    attrs: { value: _vm.question.text },
                  })
                : _vm.finale
                ? _c("JeopardAiLabel", { staticClass: "mt-4" }, [
                    _vm._v(" FINAL "),
                  ])
                : _vm.question.double
                ? _c("JeopardAiLabel", { staticClass: "mt-4" }, [
                    _vm._v(" DAILY DOUBLE "),
                  ])
                : _vm._e(),
              _vm.button &&
              (_vm.dailyDoubleTeam == null ||
                _vm.dailyDoubleTeam.id === _vm.viewer.teamID)
                ? _c("JeopardAiHint", [
                    _vm._v(" Buzz in to represent your team "),
                  ])
                : _vm.answer
                ? _c(
                    "JeopardAiHint",
                    { class: { "mb-3": !(_vm.count > -1 || _vm.button) } },
                    [_vm._v(" " + _vm._s(_vm.answer) + " ")]
                  )
                : _vm._e(),
              _vm.dailyDoubleTeam == null && (_vm.count > -1 || _vm.button)
                ? _c(
                    "div",
                    {
                      key: "featured",
                      staticClass: "jeopardai-question-mobile__featured",
                    },
                    [
                      _vm.dailyDoubleTeam == null
                        ? [
                            _vm.count > -1
                              ? _c("JeopardAiTimer", [
                                  _vm._v(" " + _vm._s(_vm.count) + " "),
                                ])
                              : _vm.button
                              ? _c("BigUglyButton", {
                                  staticClass:
                                    "jeopardai-question-mobile__button",
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.submit()
                                    },
                                  },
                                })
                              : _vm._e(),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
              _vm.dailyDoubleTeam &&
              _vm.dailyDoubleTeam.id !== _vm.viewer.teamID
                ? _c("JeopardAiTitle", {
                    staticClass: "mt-2 mb-3",
                    attrs: { value: _vm.dailyDoubleTeam.name + " only!" },
                  })
                : _vm._e(),
              !_vm.finale
                ? _c("JeopardAiPrice", {
                    staticClass: "jeopardai-question-mobile__price",
                    attrs: { amount: _vm.amount },
                  })
                : _vm._e(),
            ],
            1
          ),
      _vm.wager
        ? _c("JeopardAiWager", {
            staticClass: "jeopardai-question-mobile__wager",
            attrs: {
              finale: _vm.finale,
              question: _vm.state.finale,
              user: _vm.self,
              min: _vm.min,
            },
            on: {
              submitWagerAmount: _vm.submitWagerAmount,
              submitWagerAnswer: _vm.submitWagerAnswer,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }